<template>
  <v-container fluid>
    <v-card>
      <v-toolbar color="secondary" flat>
        <v-toolbar-title>Inventory - Stock Balance Report</v-toolbar-title>
        <v-spacer></v-spacer>

        <v-spacer></v-spacer>

        <v-col cols="2">
          <v-btn icon class="ml-10" right @click="getStockBalanceReport">
            <v-icon>mdi-sync</v-icon>
          </v-btn>
        </v-col>
      </v-toolbar>

      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-text-field
                v-model="search"
                append-icon="mdi-search"
                label="Search"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="12">
            <v-data-table
                :loading="loading"
                loading-text="Loading... Please wait"
                :headers="headers"
                :items="inventoryReports"
                :items-per-page="options.itemsPerPageOptions"
                :search="search"
                :options.sync="options"
                :server-items-length="totalItems"
                class="elevation-1"
                :server-search="true"
                :footer-props="{
                itemsPerPageOptions: [15, 30, 45, { text: 'All', value: -1 }],
                showCurrentPage: true,
              }"
            >
            </v-data-table>
          </v-col>
        </v-row>
        <snackbar ref="snackbar"></snackbar>
      </v-card-text>
    </v-card>

    <!-- category quick add dialog -->
    <v-dialog v-model="errorDocDialog" max-width="650px">
      <v-card>
        <v-toolbar dense color="red" dark>
          <v-toolbar-title>Sync Process Error Message</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click.native="errorDocDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container fluid>
            <v-row>
              <v-col cols="12">
                This document was not sync to SAP due to below following error:
              </v-col>
              <v-col cols="12">
                <span>
                  {{ this.ErrorMessage }}
                </span>
              </v-col>

              <v-row>
                <v-col cols="6">
                  <v-btn color="green" dark @click="errorDocDialog = false"
                  >Close</v-btn
                  >
                </v-col>
              </v-row>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- end of modal-->
  </v-container>
</template>

<script>
export default {
  data: () => ({
    search: "",
    inventoryReports: [],
    errorDocDialog: false,
    ErrorMessage: null,
    loading: false,
    headers: [
      { text: "Item Code", value: "ItemCode" },
      { text: "Warehouse", value: "WhsCode" },
      { text: "Item Name", value: "ItemName" },
      { text: "On Hand", value: "OnHand" },
      { text: "Is Commited", value: "IsCommited" },
    ],
    options: {
      // rowsPerPageOptions: [10, 20, 30],
      // itemsPerPageOptions: [10, 20, 30],
      itemsPerPage: 15,
      page: 1,
    },
    totalItems: 0,
  }),
  watch: {
    options: {
      handler() {
        this.getStockBalanceReport();
      },
      deep: true,
    },
    search() {
      this.options.page = 1;
      if (this.search.length >= 3) {
        this.getStockBalanceReport();
      }
    },
  },
  methods: {
    getStockBalanceReport() {
      const self = this;
      this.loading = true;
      this.$store
          .dispatch("get", `/inventory/report?page=${self.options.page}&per_page=${self.options.itemsPerPage}`)
          .then((res) => {
            if (res.ResultCode == 1200) {
              self.inventoryReports = res.ResponseData.data;
              self.options.page = res.ResponseData.current_page;
              self.options.itemsPerPage = res.ResponseData.per_page;
              self.totalItems = res.ResponseData.total;
              self.loading = false;
            }
          })
          .catch((err) => {
            this.$refs.snackbar.show(err, "red");
          });
    },
    checkRights() {
      const self = this;
      this.$store
          .dispatch("get", `/authorization/check-if-permitted/3`)
          .then((res) => {
            self.docRights = res;
          })
          .catch((err) => {
            this.$refs.snackbar.show(err, "red");
          });
    },
    openerrorDocDialog(item) {
      this.ErrorMessage = item.ErrorMessage;
      this.errorDocDialog = true;
    },
  },
  created() {
    // this.getSerialNumbersReport();
  },
};
</script>